import { combineReducers } from 'redux';

import * as routing from './routing';
import * as members from './member';
import * as blogs from './blog';
import * as i18n from './i18n';
import * as news from './news';
import * as global from './global';
import * as homeBanner from './homeBanner';
import * as event from './event';
import * as preview from './preview';
import * as multiMedia from './multiMedia';
import * as read from './read';
import * as fountain from './fountain';
import * as award from './award';
import * as publication from './publication';
import * as modal from './modal';
import * as auth from './auth';
import * as user from './user';
import * as product from './product';
import * as cart from './cart';
import * as order from './order';
import * as campusambassador from './campusambassador';
import * as orgStructure from './orgStructure';

// For Global State interface
export interface State {
	blogs: blogs.State;
	members: members.State;
	routing: routing.State;
	i18n: i18n.State;
	news: news.State;
	global: global.State;
	homeBanner: homeBanner.State;
	event: event.State;
	preview: preview.State;
	multiMedia: multiMedia.State;
	read: read.State;
	fountain: fountain.State;
	award: award.State;
	publication: publication.State;
	modal: modal.State;
	auth: auth.State;
	user: user.State;
	product: product.State;
	cart: cart.State;
	order: order.State;
	campusambassador: campusambassador.State;
	orgStructure: orgStructure.State;
}

export type GetState = () => State;

export const defaultState: State = {
	blogs: blogs.defaultState,
	members: members.defaultState,
	routing: routing.defaultState,
	i18n: i18n.defaultState,
	news: news.defaultState,
	global: global.defaultState,
	homeBanner: homeBanner.defaultState,
	event: event.defaultState,
	preview: preview.defaultState,
	multiMedia: multiMedia.defaultState,
	read: read.defaultState,
	fountain: fountain.defaultState,
	award: award.defaultState,
	publication: publication.defaultState,
	modal: modal.defaultState,
	auth: auth.defaultState,
	user: user.defaultState,
	product: product.defaultState,
	cart: cart.defaultState,
	order: order.defaultState,
	campusambassador: campusambassador.defaultState,
	orgStructure: orgStructure.defaultState,
};

const reducers = combineReducers<State>({
	...routing.reducer,
	...members.reducer,
	...blogs.reducer,
	...i18n.reducer,
	...news.reducer,
	...global.reducer,
	...homeBanner.reducer,
	...event.reducer,
	...preview.reducer,
	...multiMedia.reducer,
	...read.reducer,
	...fountain.reducer,
	...award.reducer,
	...publication.reducer,
	...modal.reducer,
	...auth.reducer,
	...user.reducer,
	...product.reducer,
	...cart.reducer,
	...order.reducer,
	...campusambassador.reducer,
	...orgStructure.reducer,
});

export default reducers;
