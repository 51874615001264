import { createAction, handleActions, Action } from 'redux-actions';
import { createSelector } from 'reselect';
import { useRedux } from 'util/hook/redux';
import { wrapFetch } from 'util/api';
import i18n from 'i18next';

import { chunkArrayByLength, objectToNameArray } from 'util/helper';

import { State as GlobalState } from './reducers';


export interface ExecutiveMemberProperty {
	name: string;
	convener: boolean;
}

export interface ExecutiveMemberPayload {
	executiveCommittee: string[][];
	advisoryCommittee: string[][];
	alternateExecutiveCommittee: string[] | string[][];
	alternateAdvisoryCommittee: string[] | string[][];
	mobileExecutiveCommittee: string[];
}

export interface OrgDescriptionListProperty {
	title: string;
	text: string;
}

export interface OrgDescriptionPayload {
	description: string;
	image: string;
	list: OrgDescriptionListProperty[];
}

export interface OrgMainMemberProperty {
	image: string;
	name: string;
	job: string;
	gender: string;
	nowJob: string;
	education: string[];
	experience: string[];
}

export const getOrgDescription = createAction<Promise<OrgDescriptionPayload>, string>('GET_ORG_DESCRIPTION', async(lang: string) => {
	try {
		const { data } = await wrapFetch(
			`organization/${lang}/description`,
			{
				method: 'GET',
			},
		);
		return data.data;
	} catch(error) {
		throw new Error((error as Error).message);
	}
});

export const getMainMembers = createAction<Promise<OrgMainMemberProperty>, string>('GET_MAIN_MEMBERS', async(lang: string) => {
	try {
		const { data } = await wrapFetch(
			`organization/${lang}/main-members`,
			{
				method: 'GET',
			},
		);
		return data.data;
	} catch(error) {
		throw new Error((error as Error).message);
	}
});

export const getExecutiveMembers = createAction<Promise<ExecutiveMemberPayload>, string>(
	'GET_EXECUTIVE_MEMBERS', async(lang: string) => {
		try {
			const { data } = await wrapFetch(
				`organization/${lang}/committees`,
				{
					method: 'GET',
				},
			);
			// start 處理執行委員
			const executiveCommittee = objectToNameArray(data.data.executiveCommittee);
			const finalExecutiveCommittee = chunkArrayByLength(executiveCommittee, 6);
			// end 處理執行委員

			// start處理諮議委員
			const advisoryCommittee = data.data.advisoryCommittee.map((item: ExecutiveMemberProperty) => {
				if (item.convener) {
					if (i18n.language === 'EN') {
						return `${item.name}（Convenor）`;
					}
					return `${item.name}（召集人）`;
				}
				return item.name;
			});
			const conveners = advisoryCommittee.filter((name: string) => name.includes('召集人') || name.includes('Convenor'));
			const others = advisoryCommittee.filter((name: string) => !name.includes('召集人') && !name.includes('Convenor'));
			const groupedOthers = chunkArrayByLength(others, 2);
			const finalAdvisoryCommittee = [...conveners.map((c: string) => [c]), ...groupedOthers];
			// end 處理諮議委員

			// start 處理候補執行委員
			const altExecutiveCommittee = objectToNameArray(data.data.alternateExecutiveCommittee);
			let finalAltExecutive: string[] | string[][] = altExecutiveCommittee;
			// 大於六個人時，要分組為兩個名字一個陣列
			if (altExecutiveCommittee.length > 6) {
				finalAltExecutive = chunkArrayByLength(altExecutiveCommittee, 2);
			}
			// end 處理候補執行委員

			// start 處理候補諮議委員
			const altAdvisoryCommittee = objectToNameArray(data.data.alternateAdvisoryCommittee);
			let finalAltAdvisory: string[] | string[][] = altAdvisoryCommittee;
			// 大於六個人時，要分組為兩個名字一個陣列
			if (altAdvisoryCommittee.length > 6) {
				finalAltAdvisory = chunkArrayByLength(altExecutiveCommittee, 2);
			}
			// end 處理候補諮議委員

			return {
				executiveCommittee: finalExecutiveCommittee,
				// 手機版的執行委員直接用陣列顯示，不用分組成二維陣列
				mobileExecutiveCommittee: executiveCommittee,
				advisoryCommittee: finalAdvisoryCommittee,
				alternateExecutiveCommittee: finalAltExecutive,
				alternateAdvisoryCommittee: finalAltAdvisory,
			};
		} catch (error) {
			throw new Error((error as Error).message);
		}
	},
);

export const cleanExecutiveMembers = createAction('CLEAN_EXECUTIVE_MEMBERS');

export interface State {
	loading: boolean;
	executiveMembers: ExecutiveMemberPayload;
	descriptionContext: OrgDescriptionPayload;
	mainMembers: OrgMainMemberProperty[];
}

export const defaultState: State = {
	loading: false,
	executiveMembers: {
		executiveCommittee: [],
		advisoryCommittee: [],
		alternateExecutiveCommittee: [],
		alternateAdvisoryCommittee: [],
		mobileExecutiveCommittee: [],
	},
	descriptionContext: {
		description: '',
		image: '',
		list: [],
	},
	mainMembers:[],
};

export const reducer = {
	orgStructure: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_EXECUTIVE_MEMBERS_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_EXECUTIVE_MEMBERS_FULFILLED: (state, action: Action<ExecutiveMemberPayload>) => ({
				...state,
				executiveMembers: action.payload,
				loading: false,
			}),

			GET_ORG_DESCRIPTION_FULFILLED: (state, action: Action<OrgDescriptionPayload>) => ({
				...state,
				descriptionContext: action.payload,
				loading: false,
			}),

			GET_MAIN_MEMBERS_FULFILLED: (state, action: Action<OrgMainMemberProperty[]>) => ({
				...state,
				mainMembers: action.payload,
				loading: false,
			}),

			CLEAN_EXECUTIVE_MEMBERS: state => ({
				...state,
				executiveMembers: {
					executiveCommittee: [],
					advisoryCommittee: [],
					alternateExecutiveCommittee: [],
					alternateAdvisoryCommittee: [],
					mobileExecutiveCommittee: [],
				},
			}),
		},

		defaultState,
	),
};

const selectMember = createSelector(
	(state: GlobalState) => state.orgStructure.executiveMembers,
	(state: GlobalState) => state.orgStructure.descriptionContext,
	(state: GlobalState) => state.orgStructure.mainMembers,
	(executiveMembers, descriptionContext, mainMembers) => ({
		executiveMembers,
		descriptionContext,
		mainMembers
	}),
);

const memberActionsMap = {
	getExecutiveMembers,
	getOrgDescription,
	getMainMembers,
};

export const useOrgStructure = () => useRedux(selectMember, memberActionsMap);