import React from 'react';

import {
	getExecutiveMembers,
	getOrgDescription,
	getMainMembers,
	cleanExecutiveMembers,
} from 'models/orgStructure';

import { CustomRoute } from 'util/hook/useRouter';


const routes: CustomRoute = {
	path: '/org-structure',
	components: () => [],
	render: (_, children) => children,
	onEnter: async () => {},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'orgStructure' */ './component')],
			render: ([OrgStructure]) => <OrgStructure />,
			onEnter: async ({ next, store }) => {
				console.log('on Enter orgStructure');
				await store.dispatch(cleanExecutiveMembers());
				const {i18n: { lang }} = store.getState();
				console.log('on Enter orgStructure / lang', lang);
				switch (lang) {
					case 'TW':
						await store.dispatch(getExecutiveMembers('zh'));
						await store.dispatch(getMainMembers('zh'));
						await store.dispatch(getOrgDescription('zh'));
						break;
					case 'EN':
						await store.dispatch(getExecutiveMembers('en'));
						await store.dispatch(getOrgDescription('en'));
						await store.dispatch(getMainMembers('en'));
						break;
					case 'JP':
						await store.dispatch(getExecutiveMembers('ja'));
						await store.dispatch(getOrgDescription('ja'));
						await store.dispatch(getMainMembers('ja'));
						break;
					default:
						await store.dispatch(getExecutiveMembers('zh'));
						await store.dispatch(getOrgDescription('zh'));
						await store.dispatch(getMainMembers('zh'));
						break;
				}

				await next();
				console.log('on Enter orgStructure / end');
			},
		},
	],
};

export default routes;
